<template>
  <div class="church-members-friends template-1">
    <page-header class="mx-lg">
      <h1>{{ translations.tcManageAnnouncements }}</h1>
    </page-header>
    <page-body class="mx-lg">
      <div>
        <b-form>
          <b-row>
            <b-col sm="6" class="font-style-3">
              <b-button @click="handleBackClick" variant="primary" class="btn-w-sm flex-0 mr-0 mr-sm-3 w-100-sd mb-0">
                {{ translations.tcBack }}
              </b-button>
            </b-col>
            <b-col sm="6" class="font-style-3">
              <Search @handleSearchClick="handleSearchClick($event)" />
            </b-col>
          </b-row>
        </b-form>
      </div>
      <div v-for="(anx, inx) in Object.values(announcements_collection)" :key="inx">
        <data-table :fields="inx === 3 ? cmtFields : fields" :listType="'announcement'" :i18n="translations.components"
          :items="anx" :hideMe="anx === null" :addItems="addItems[inx]" :title="`${announcements_titles[inx]}`"
          :toggle="true" :toggleMe="true" :includeAddButton="true" :hideHeader="true" :searchTerm="`announcementsTerm`"
          @addAnnouncement="addAnnouncement" @deleteAnnouncement="handleDeleteRequest($event)"
          @editAnnouncement="handleEditRequest($event)"></data-table>
      </div>
    </page-body>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import DataTable from '@/components/DataTable.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import Search from '@/components/SearchInput.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'manage-announcements',
  mixins: [translationMixin],
  data() {
    return {
      translations: {},
      search: '',
      secured_announcement_panel_controls: {
        view_camp_announcement_manage_panel: 'e957e006-d655-40b6-892c-ea020bdf4e51',
        view_state_announcement_manage_panel: '16cf23eb-b0b9-4b1c-a094-31ad3b7cf094',
        view_country_announcement_manage_panel: '1ee94f7a-38b2-4d04-a68d-3bb3a00a7ac7',
        view_committee_announcement_manage_panel: '20c79f25-85b2-4b52-ba3f-aabc2892a14e',
        view_international_announcement_manage_panel: '775ef4f5-a2d2-46fb-bc39-09bbba0ac7bf',
        view_international_announcement_add_button: '1c86f78d-d4f8-4845-83bd-e96784582cc6',
        view_committee_announcement_add_button: 'fe4b0c01-e2f8-4d5d-825f-52472bbbeddd',
        view_country_announcement_add_button: '789c7b00-95c2-4eda-a4aa-d661e4004cc6',
        view_state_announcement_add_button: '183af598-be68-4ef2-93b5-3949f027db83',
        view_camp_announcement_add_button: '05de1755-ddaa-4a45-a459-e55122cfe128',
      },
      cmtFields: [
        { key: 'sortdate', label: 'Added', sortable: true },
        { key: 'expdate', label: 'Expires', sortable: true },
        { key: 'category', label: 'Committee', sortable: true },
        { key: 'msg_title', label: 'Title', sortable: true },
        { key: 'attachments', label: 'Attachments', sortable: true },
        { key: 'msg_message_id', label: 'Edit/Delete', sortable: false },
      ],
      fields: [
        { key: 'sortdate', label: 'Added', sortable: true },
        { key: 'expdate', label: 'Expires', sortable: true },
        { key: 'msg_title', label: 'Title', sortable: true },
        { key: 'attachments', label: 'Attachments', sortable: true },
        { key: 'msg_message_id', label: 'Edit/Delete', sortable: false },
      ],
      hiddenItems: {
        display: false,
      },
      addItems: [
        { display: false },
        { display: false },
        { display: false },
        { display: false },
        { display: false },
        { display: false },
      ],
      addItemsObject: { display: true, action: 'addAnnouncement', text: 'Add Announcement' },
      announcements_collection: {
        camp_announcements: [],
        state_announcements: [],
        country_announcements: [],
        committee_announcements: [],
      },
      announcements_titles: ['Camp', 'State', 'Country', 'Committee', 'International'],
    }
  },
  methods: {
    ...mapActions({
      deleteAnnouncementMessage: 'announcements/deleteAnnouncementMessage',
      deleteAnnouncementLocally: 'announcements/deleteAnnouncementLocally',
      getAddableCommittees: 'announcements/getAddableCommittees',
      getAnnouncementsForEdit: 'announcements/getAnnouncementsForEdit',
      getCampStateLeadership: 'announcements/getCampStateLeadership',
      getCommitteeMembers: 'announcements/getCommitteeMembers',
      getCountryZoneMembers: 'announcements/getCountryZoneMembers',
      getCountryZoneCountries: 'announcements/getCountryZoneCountries',
      getCountryZones: 'announcements/getCountryZones',
      getRegionalCommittees: 'announcements/getRegionalCommittees',
      getStateLeadership: 'announcements/getStateLeadership',
      getStateRegionArea: 'announcements/getStateRegionArea',
      setDataForEditingAnnouncement: 'announcements/setDataForEditingAnnouncement',
      setLoadingStatus: 'menu/setLoadingStatus',
      setMessagePackageForDelete: 'announcements/setMessagePackageForDelete',
      setSelectedAnnouncementKey: 'user/setSelectedAnnouncementKey',
      setStateRegionsForEdit: 'announcements/setStateRegionsForEdit',
      setUserCommitteesForEdit: 'announcements/setUserCommitteesForEdit',
    }),
    async page_load() {
      try {
        this.setLoadingStatus(true)
        await Promise.all([
          this.getAnnouncementsForEdit({
            ind_key: this.userId,
            state_key: this.userStateKey,
            cmp_key: null,
            ga_key: this.userMemberTypeKey,
            lng_key: this.userLanguageKey,
            quick: false,
            search_term: this.search,
            cnt_key: this.userStateKey,
            camp_ok: this.iCanSee(this.secured_announcement_panel_controls.view_camp_announcement_manage_panel) ? 1 : 0,
            state_ok: this.iCanSee(this.secured_announcement_panel_controls.view_state_announcement_manage_panel)
              ? 1
              : 0,
            country_ok: this.iCanSee(this.secured_announcement_panel_controls.view_country_announcement_manage_panel)
              ? 1
              : 0,
            committee_ok: this.iCanSee(
              this.secured_announcement_panel_controls.view_committee_announcement_manage_panel
            )
              ? 1
              : 0,
          }),
          this.getRegionalCommittees(),
          this.getAddableCommittees(),
        ]).then(() => {
          this.divideAnnouncements()
        })
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    addAnnouncement() {
      this.$router.push('/get-connected/stay-informed/announcements/add/add-announcement')
    },
    async divideAnnouncements() {
      await (this.announcements_collection.camp_announcements = this.iCanSee(
        this.secured_announcement_panel_controls.view_camp_announcement_manage_panel
      )
        ? this.applyFilter('camp')
        : null)
      await (this.announcements_collection.state_announcements =
        this.iCanSee(this.secured_announcement_panel_controls.view_state_announcement_manage_panel) &&
          this.userLogin.membertype.toLowerCase() === "usa"
          ? this.applyFilter('state')
          : null)
      await (this.announcements_collection.country_announcements =
        this.iCanSee(this.secured_announcement_panel_controls.view_country_announcement_manage_panel) &&
          ['usa', 'ioc'].includes(this.userLogin.membertype.toLowerCase()) // === "usa"
          ? this.applyFilter('country')
          : null)
      await (this.announcements_collection.committee_announcements =
        this.iCanSee(this.secured_announcement_panel_controls.view_committee_announcement_manage_panel) &&
          ((!!this.addableCommittees.areas && this.addableCommittees.areas.length > 1) ||
            this.regionalCommittees.length > 0)
          ? this.applyFilter('committee')
          : null)
    },
    applyFilter(tcat) {
      try {
        let afe = this.announcementsForEdit
          .map((cm) => {
            return {
              sortdate: cm.sortdate,
              cat: cm.cat,
              category: cm.category,
              expdate: cm.expdate,
              msg_message_id: cm.msg_message_id,
              attachments: cm.attachments.length > 0 ? cm.attachments[0].name.split('/')[7] : '',
              msg_title: cm.msg_title,
            }
          })
          .filter((ca) => ca.cat.includes(tcat))

        if (tcat === 'committee') {
          let cmn = this.userLogin.cpositions.map((v) => v.committee_name)
          afe = afe.filter((fe) => cmn.includes(fe.category))
        }
        return afe
      } catch (ValueError) {
        return null
      }
    },
    handleBackClick() {
      this.$router.push({ path: '/get-connected/stay-informed/announcements' })
    },
    async handleDelete(msgid) {
      try {
        let del_result = true
        this.setLoadingStatus(true)
        await Promise.all([
          await this.setMessagePackageForDelete(msgid),
          (del_result = await this.deleteAnnouncementMessage()),
        ]).then(() => {
          if (del_result) {
            this.deleteAnnouncementLocally(msgid)
            this.divideAnnouncements()
          }
          this.$swal({
            icon: del_result ? 'success' : 'error',
            showCancelButton: false,
            text: del_result ? this.translations.tcDeleteSuccessful : this.translations.tcDeleteFailed,
            confirmButtonText: this.translations.tcOk || 'Ok',
          })
        })
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    async handleDeleteRequest(msgid) {
      this.$swal({
        icon: 'question',
        showCancelButton: true,
        cancelButtonColor: '#d33',
        text: this.translations.tcOkayToDeleteAnnouncement,
        confirmButtonText: this.translations.tcOk || 'Ok',
        cancelButtonText: this.translations.tcCancel || 'Cancel',
      }).then((result) => {
        if (result.value) {
          this.handleDelete(msgid)
        }
      })
    },
    async handleSearchClick(searchTerm) {
      this.search = searchTerm
      await this.page_load()
    },
    async handleEditRequest(msgid) {
      await this.setLoadingStatus(true)
      try {
        await this.setSelectedAnnouncementKey(msgid)
        await this.setDataForEditingAnnouncement(msgid)
        if (this.announcementForm.type.indexOf('CommitteesMembers') > -1) {
          await this.setUserCommitteesForEdit(this.announcementForm.geo_array)
          await this.getCommitteeMembers(
            this.selectedUserCommittees.areas.map((suc) => suc.value),
            this.announcementForm.aud_array[0] // because the user editing may not be on the committee
          )
        }
        if (
          this.announcementForm.type.indexOf('AreasManagement') > -1 ||
          this.announcementForm.type.indexOf('ZonesManagement') > -1
        ) {
          await this.getCommitteeMembers(this.announcementForm.aud_array, null)
        }
        if (!!this.announcementForm.state_key) {
          await this.getStateRegionArea(this.announcementForm.state_key)
          await this.setStateRegionsForEdit()
        }
        if (this.announcementForm.type.indexOf('RCommitteeCamp') > -1) {
          await this.getCountryZoneCountries(this.regionalCommitteeKey)
        }
        if (this.announcementForm.type.indexOf('RCommitteeManagement') > -1) {
          await this.getCountryZoneCountries(this.regionalCommitteeKey)
        }
        if (this.announcementForm.type.indexOf('RCommitteeMember') > -1) {
          await this.getCountryZoneCountries(this.regionalCommitteeKey)
        }
        if (this.announcementForm.type.indexOf('AreasCamp') > -1) {
          await this.getStateLeadership()
          await this.getCampStateLeadership('Camp')
          if (this.userCountryObject.type === 'domestic') {
            await this.getStateRegionArea(null)
          } else {
            await this.getCountryZones(this.userStateKey)
          }
        }
        if (this.announcementForm.type.indexOf('AreasMembers') > -1) {
          await this.getStateLeadership()
          await this.getCampStateLeadership('Camp')
          if (this.userCountryObject.type === 'domestic') {
            await this.getStateRegionArea(null)
          } else {
            await this.getCountryZones(this.userStateKey)
          }
        }
        if (this.announcementForm.type.indexOf('RCommitteeRCMember') > -1) {
          await this.getCountryZoneMembers(this.announcementForm.region)
          await this.getCountryZoneCountries(this.regionalCommitteeKey)
        }
        this.$router.push('/admin/announcements/edit-announcement')
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    setTranslations() {
      this.announcements_titles = [
        this.translations.tcCampAnnouncements || 'Camp Announcements',
        this.translations.tcStateAnnouncements || 'State Announcements',
        this.translations.tcCountryAnnouncements || 'Country Announcements',
        this.translations.tcCommitteeAnnouncements || 'Committee Announcements',
        this.translations.tcInternationalAnnouncements || 'International Announcements',
      ]
      this.cmtFields[0].label = this.translations.tcAdded
      this.cmtFields[1].label = this.translations.tcExpires
      this.cmtFields[2].label = this.translations.tcCommittee
      this.cmtFields[3].label = this.translations.tcTitle
      this.cmtFields[4].label = this.translations.tcAttachments
      this.cmtFields[5].label = this.translations.tcEditOrDelete
      this.fields[0].label = this.translations.tcAdded
      this.fields[1].label = this.translations.tcExpires
      this.fields[2].label = this.translations.tcTitle
      this.fields[3].label = this.translations.tcAttachments
      this.fields[4].label = this.translations.tcEditOrDelete
    },
  },
  async mounted() {
    await (async () => {
      if (this.iCanSee(this.secured_announcement_panel_controls.view_international_announcement_add_button)) {
        this.addItems[4] = this.addItemsObject
      }
      if (this.iCanSee(this.secured_announcement_panel_controls.view_committee_announcement_add_button)) {
        this.addItems[3] = this.addItemsObject
      }
      if (this.iCanSee(this.secured_announcement_panel_controls.view_country_announcement_add_button)) {
        this.addItems[2] = this.addItemsObject
      }
      if (this.iCanSee(this.secured_announcement_panel_controls.view_state_announcement_add_button)) {
        this.addItems[1] = this.addItemsObject
      }
      if (this.iCanSee(this.secured_announcement_panel_controls.view_camp_announcement_add_button)) {
        this.addItems[0] = this.addItemsObject
      }
    })()
  },
  async created() {
    await Promise.all([
      await this.getViewTranslations(),
      await this.getComponentTranslations('data-table'),
      await this.page_load(),
    ]).then((results) => {
      const componentTranslations = results[1]
      this.$set(this.translations, 'components', componentTranslations)
      this.setTranslations()
    })
  },
  computed: {
    ...mapGetters({
      addableCommittees: 'announcements/addableCommittees',
      announcementsForEdit: 'announcements/announcementsForEdit',
      announcementForm: 'announcements/announcementForm',
      iCanSee: 'user/iCanSee',
      prefCulture: 'user/userPreferredCulture',
      regionalCommitteeKey: 'announcements/regionalCommitteeKey',
      regionalCommittees: 'announcements/regionalCommittees',
      selectedUserCommittees: 'announcements/selectedUserCommittees',
      userCountryKey: 'user/userCountryKey',
      userCountryObject: 'user/userCountryObject',
      userId: 'user/userId',
      userLanguageKey: 'user/userLanguageKey',
      userLogin: 'user/userLogin',
      userMemberTypeKey: 'user/userMemberTypeKey',
      userStateKey: 'user/userStateKey',
    }),
  },
  components: {
    DataTable: DataTable,
    pageBody: pageBody,
    pageFooter: pageFooter,
    pageHeader: pageHeader,
    Search: Search,
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';

.church-members-friends {
  margin-bottom: 60px;

  .list.container {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 40px;
  }

  .closed-toggle {
    padding: 40px 30px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    h2 {
      flex: 1 1 auto;
      margin: 0;
      color: #000;
      font-family: $DIN;
      font-size: 42px;
      letter-spacing: 1.4px;
      line-height: 44px;
      text-transform: uppercase;
    }

    .toggle-arrow svg {
      transform-origin: center;
      transform: rotate(180deg);
    }
  }
}
</style>
